import React, { FC, ReactElement, useCallback } from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';

import extractUrlFromMultiPicker from 'utils/urlMultiPicker';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import { scrollToTarget } from 'utils/browser';
import { IPropsProductLink } from './model';
import './ProductLink.scss';

const ProductLink: FC<IPropsProductLink> = ({
  isHidden,
  anchorTargetId,
  productLink,
  retailerClickHandler,
}): ReactElement | null => {
  const inactiveBgColor =
    productLink.btnStyles?.[0]?.properties?.inactiveBgColor?.[0]?.properties?.colorPicker?.label ||
    '';
  const inactiveTextColor =
    productLink.btnStyles?.[0]?.properties?.inactiveTextColor?.[0]?.properties?.colorPicker
      ?.label || '';
  const activeBgColor =
    productLink.btnStyles?.[0]?.properties?.activeBgColor?.[0]?.properties?.colorPicker?.label ||
    '';
  const activeTextColor =
    productLink.btnStyles?.[0]?.properties?.activeTextColor?.[0]?.properties?.colorPicker?.label ||
    '';
  const disabledBgColor =
    productLink.btnStyles?.[0]?.properties?.disabledBgColor?.[0]?.properties?.colorPicker?.label ||
    '';
  const disabledTextColor =
    productLink.btnStyles?.[0]?.properties?.disabledTextColor?.[0]?.properties?.colorPicker
      ?.label || '';

  const handleClickAnchor = useCallback(
    (id: string) => () => {
      scrollToTarget(id);
    },
    []
  );

  const handleRetailerClick = useCallback(() => {
    if (typeof retailerClickHandler === 'function') retailerClickHandler();
  }, []);

  if (isHidden) {
    return null;
  }

  const activeBgColorApplied = activeBgColor ? ` ${activeBgColor}-active-bg` : '';
  const activeTextColorApplied = activeTextColor ? ` ${activeTextColor}-active-text` : '';
  const inactiveBgColorApplied = inactiveBgColor ? ` ${inactiveBgColor}-default-bg` : '';
  const inactiveTextColorApplied = inactiveTextColor ? ` ${inactiveTextColor}-default-text` : '';
  const disabledBgColorApplied = disabledBgColor ? ` ${disabledBgColor}-disabled-bg` : '';
  const disabledTextColorApplied = disabledTextColor ? ` ${disabledTextColor}-disabled-text` : '';

  const classes = classNames('product-link-external', {
    [activeBgColorApplied]: activeBgColorApplied,
    [activeTextColorApplied]: activeTextColorApplied,
    [inactiveBgColorApplied]: inactiveBgColorApplied,
    [inactiveTextColorApplied]: inactiveTextColorApplied,
    [disabledBgColorApplied]: disabledBgColorApplied,
    [disabledTextColorApplied]: disabledTextColorApplied,
  });

  return (
    <div className="hf-product-link" data-test="ProductLink">
      <a
        href={extractUrlFromMultiPicker(productLink.link)}
        className={classes}
        target="_blank"
        rel="noreferrer"
        aria-label={productLink?.shopBtnAriaLabel}
        onClick={handleRetailerClick}
      >
        {productLink.link[0]?.name}
        <DangerouslySetInnerHtml element="span" html={productLink.icon?.svg?.content} />
      </a>
      {productLink.anchorTitle && anchorTargetId ? (
        <button
          type="button"
          className="product-link-internal"
          onClick={handleClickAnchor(anchorTargetId)}
          aria-label={productLink.ariaLabel}
        >
          {productLink.anchorTitle}
        </button>
      ) : null}
    </div>
  );
};

export const query = graphql`
  fragment FragmentProductLink on IProductLink {
    ariaLabel
    anchorTitle
    shopBtnAriaLabel
    link {
      url
      name
    }
    btnStyles {
      properties {
        activeBgColor {
          ...FragmentColorProps
        }
        activeTextColor {
          ...FragmentColorProps
        }
        disabledBgColor {
          ...FragmentColorProps
        }
        disabledTextColor {
          ...FragmentColorProps
        }
        inactiveBgColor {
          ...FragmentColorProps
        }
        inactiveTextColor {
          ...FragmentColorProps
        }
      }
    }
    icon {
      fallbackUrl
      svg {
        content
      }
    }
    shopName
  }
`;

export default ProductLink;
